import React from "react";
import {Container} from "react-bootstrap";

export default function Footer() {
    return(
        <section className={"bg-dark p-3 border-top border-secondary"} style={{background:"#343a40"}}>
            <Container className={"text-white text-center"}>
                <a href={"https://itsmyweb.in"} className={"no-text-decor"} target={"_blank"} rel={"noreferrer"}>
                    <span className={"text-secondary"}>Managed by </span><b>ItsMyWeb </b>
                    <span className={"text-secondary"}>on </span>
                    <span className={"text-primary"}>itsmyweb.in</span>
                </a>
            </Container>
        </section>
    );
}
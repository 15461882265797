import {PUBLIC_GET_ABOUT_US_API, PUBLIC_IS_ENABLED_ABOUT_US_API} from "../../../../constants/backend/ApiPublicConstant";
import axios from "axios";
import {optionProvider} from "../../RequestConfig";
import {SERVICE_ACCOUNT_PARAM} from "../../../../constants/ParamConstant";

export function isEnabledAboutUsPage(props, serviceAccount, successCallback, errorCallback) {
    axios.get(PUBLIC_IS_ENABLED_ABOUT_US_API.replace(SERVICE_ACCOUNT_PARAM, "/" + serviceAccount), optionProvider(props)).then(result => {
        successCallback(result);
    }).catch(error => {
        errorCallback(error);
    });
}

export function getAboutUsPage(props, serviceAccount, successCallback, errorCallback) {
    axios.get(PUBLIC_GET_ABOUT_US_API.replace(SERVICE_ACCOUNT_PARAM, serviceAccount), optionProvider(props)).then(result => {
        successCallback(result);
    }).catch(error => {
        errorCallback(error);
    });
}
import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {isEnabledAboutUsPage} from "../../webrequests/service/public/ServiceHomeRequest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ServiceBottomPanel extends Component {

    constructor(props) {
        super(props);
        this.OnSuccessEnableAboutUsPage = this.OnSuccessEnableAboutUsPage.bind(this);
        this.OnFailureEnableAboutUsPage = this.OnFailureEnableAboutUsPage.bind(this);
    }

    state = {
        name: "AP Makeup Studio",
        is_loading_about_us: true
    }

    componentDidMount() {
        isEnabledAboutUsPage(this.props, this.props.account, this.OnSuccessEnableAboutUsPage, this.OnFailureEnableAboutUsPage);
    }

    OnSuccessEnableAboutUsPage(data) {
        this.setState({
            is_loading_about_us: false
        });
    }

    OnFailureEnableAboutUsPage(error) {
        console.error("[P] GET About Us Page Error: ", error);
    }

    render() {
        return(
            <section className={"bg-dark p-4"} style={{background:"#343a40"}}>
                <Container className={"text-white"}>
                    <Row>
                        <Col>
                            <b>About Us</b>
                            <div className={"text-secondary"}>
                                <p>{this.state.name}</p>
                                {
                                    !this.state.is_loading_about_us &&
                                    <Link to={this.props.account + "/about_us"} className={"no-text-decor"}>About
                                        us</Link>
                                }
                            </div>
                        </Col>
                        <Col>
                            <b>Policies</b>
                            <div className={"text-secondary"}>
                                <Link to={"/policy/service_policy"} className={"no-text-decor"}>Service Policy</Link>
                            </div>
                        </Col>
                        <Col>
                            <b>Contact {this.state.name}</b>
                            <div className={"text-secondary"}>
                                <p className={"m-0"}> Email: apmakeupstudiovns@gmail.com</p>
                                <p>Contact: +91 87652 75017</p>
                                <a href="https://wa.me/918840593635" className={"no-text-decor d-flex"} rel={"noreferrer"} target={"_blank"}>
                                    <div className={"pe-1"}>
                                        <FontAwesomeIcon icon={["fab", "whatsapp"]} color={"white"} />
                                    </div>
                                    <p className={"m-0"}>
                                        WhatsApp
                                    </p>
                                </a>
                                <a href="https://www.facebook.com/MakeupbyAnjaliPandey" className={"no-text-decor d-flex"} rel={"noreferrer"} target={"_blank"}>
                                    <div className={"pe-1"}>
                                        <FontAwesomeIcon icon={["fab", "facebook"]} color={"white"} />
                                    </div>
                                    <p className={"m-0"}>
                                        Facebook
                                    </p>
                                </a>
                                <a href="https://www.instagram.com/makeupbyanjalipandey" className={"no-text-decor d-flex"} rel={"noreferrer"} target={"_blank"}>
                                    <div className={"pe-1"}>
                                        <FontAwesomeIcon icon={["fab", "instagram"]} color={"white"} />
                                    </div>
                                    <p className={"m-0"}>
                                        Instagram
                                    </p>
                                </a>
                                <a href="https://www.youtube.com/channel/UCnUusV2obpk0g1S8v7Bn1_A" className={"no-text-decor d-flex"} rel={"noreferrer"} target={"_blank"}>
                                    <div className={"pe-1"}>
                                        <FontAwesomeIcon icon={["fab", "youtube"]} color={"white"} />
                                    </div>
                                    <p className={"m-0"}>
                                        YouTube
                                    </p>
                                </a>
                                <a href="https://www.justdial.com/Varanasi/Ap-Makeup-Studio-Durgakund/0542PX542-X542-220522085006-F9G3_BZDET" className={"no-text-decor d-flex"} rel={"noreferrer"} target={"_blank"}>
                                    <div className={"pe-1"}>
                                        <span className={"fw-bold"} style={{color: "#fff", fontSize: 16}}>J</span>
                                        <span className={""} style={{color: "#fff", fontSize: 16}}>d</span>
                                    </div>
                                    <p className={"m-0"}>
                                        JustDial
                                    </p>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <b>Address {this.state.name}</b>
                            <div className={"text-secondary"}>
                                <p className={"m-0"}>Varanasi</p>
                                <p className={"m-0"}>Uttar Pradesh, 221005</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default ServiceBottomPanel;
import {connect} from "react-redux";
import {
    showLoadingScreen
} from "./modal/modal.actions";
import {setToken, resetUserData, setUserName} from "./user/user.actions";

const mapStateToProps = ({ user }) => (
    {
        user: user
    }
)

const mapDispatchToProps = dispatch => ({
    showLoadingScreen: isShow => dispatch(showLoadingScreen(isShow)),
    setUserName: username => dispatch(setUserName(username)),
    setToken: token => dispatch(setToken(token)),
    resetUserData: dashboards => dispatch(resetUserData(dashboards))
})

export const Connector = (component) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}
import React, {Component} from 'react';
import {Container} from "react-bootstrap";

class Policies extends Component {
    render() {
        return (
            <Container>
                <div className={"p-2"}>
                    <h6>
                        Service policy
                    </h6>
                    <p>
                        This is service policy
                    </p>
                </div>
            </Container>
        );
    }
}

export default Policies;
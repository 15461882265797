import axios from "axios";
import {optionProvider} from "./RequestConfig";
import {SUCCESS_STATUS} from "../../constants/Constant";

export function BackendGETRequest(props, URL, successCallback, failureCallback) {
    axios.get(URL, optionProvider(props))
        .then(response => {
            if(response.data.status === SUCCESS_STATUS) {
                successCallback(response.data);
                return;
            }
            if(failureCallback !== undefined) {
                failureCallback(response);
            }
        })
        .catch(error => {
            console.log(error);
            if(failureCallback !== undefined) {
                failureCallback(error);
            }
        });
}

export function BackendPOSTRequest(props, URL, data, successCallback, failureCallback) {
    axios.post(URL, data, optionProvider(props))
        .then(response => {
            if(response.data.status === SUCCESS_STATUS) {
                successCallback(response.data);
                return;
            }
            if(failureCallback !== undefined) {
                failureCallback(response);
            }
        })
        .catch(error => {
            console.log(error);
            if(failureCallback !== undefined) {
                failureCallback(error);
            }
        });
}

export function BackendPOSTRequestWithImage(props, URL, data, successCallback, failureCallback) {
    let options = optionProvider(props);
    options.headers["Content-Type"] = "multipart/form-data";

    axios.post(URL, data, options)
        .then(response => {
            if(response.data.status === SUCCESS_STATUS) {
                successCallback(response.data);
                return;
            }
            if(failureCallback !== undefined) {
                failureCallback(response);
            }
        })
        .catch(error => {
            console.log(error);
            if(failureCallback !== undefined) {
                failureCallback(error);
            }
        });
}
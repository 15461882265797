import React, {Component} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import './App.css';

import Footer from "../footer/Footer";
import {Connector} from "../../../../redux/Connector";
import {
    HOME_URL,
} from "../../constants/application/ApplicationUrl";
import ServiceApp from "../public/ServiceApp";

class App extends Component {
    render() {
        return (
            <div className="App d-flex flex-column">
                <Router>
                    <Switch>
                        <Route path={HOME_URL} component={ServiceApp}/>
                    </Switch>
                    <Footer />
                </Router>
            </div>
        );
    }
}

export default Connector(App);

import {GetAppBackendUrl} from "../../utils/OnlineSellUtil";
import {SERVICE_ACCOUNT_PARAM} from "../ParamConstant";

const PUBLIC_SERVICE_ACCOUNTS_API =  GetAppBackendUrl() + "/api/service/public";

export const PUBLIC_GET_ABOUT_US_API = PUBLIC_SERVICE_ACCOUNTS_API + "/about_us";
export const PUBLIC_IS_ENABLED_ABOUT_US_API = PUBLIC_GET_ABOUT_US_API + "/enabled";

const PUBLIC_GET_SERVICE_TAB_BASE_API = PUBLIC_SERVICE_ACCOUNTS_API + "/service_tab";
export const PUBLIC_GET_SERVICE_TAB_ITEMS_API = PUBLIC_GET_SERVICE_TAB_BASE_API + "/get/business/" + SERVICE_ACCOUNT_PARAM;

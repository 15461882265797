import React, {Component} from "react";

import axios from 'axios';

import {Nav, Navbar, Container} from "react-bootstrap";
import {Connector} from "../../../../../redux/Connector";
import Authenticate from "../../webrequests/Authenticate";
import {LOGOUT_API} from "../../../constants/backend/ApiConstant";
import logo from "../../../statics/images/ap/logo.png";
import {HOME_URL} from "../../../constants/application/ApplicationUrl";

class PublicNavbarComponent extends Component {
    state = {
        navbar: [
            {
                name: "About us",
                link: "/about_us"
            },
            {
                name: "Mission",
                link: "/mission"
            },
            {
                name: "Services",
                link: "/services"
            },
            {
                name: "InstaGallery",
                link: "https://www.instagram.com/makeupbyanjalipandey/",
                new_tab: true
            },
            {
                name: "Offers",
                link: "/offers"
            },
            {
                name: "Testimonials",
                link: "/testimonials"
            },
            {
                name: "Contact us",
                link: "/contact_us"
            }
        ]
    }
    name = "AP Makeup Studio";

    responseSuccessGoogle = (response) => {
        console.log(response);
        if(response.tokenId && !this.props.user.username) {
            Authenticate(this.props, response.tokenId);
        }
    }

    responseFailureGoogle = (response) => {
        console.error(response.details);
    }


    logout = (response) => {
            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + this.props.user.token
                }
            };
            axios.post(LOGOUT_API,{},options)
                .then(res => {
                    console.log(res.data);
                })
                .catch(error => {
                    console.log(error);
                });
    }

    render() {

        return (
            <Navbar bg="light" expand="lg" fixed={"top"} sticky={"top"} className={"shadow-sm"}>
                <Container>
                <Navbar.Brand href={HOME_URL} className={"font-weight-bold"}>
                    <img src={logo} alt={"logo"} style={{height: 34}} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="m-auto">
                        {
                            this.state.navbar.map((it, index) => {
                                return <Nav.Link href={it.link} target={it.new_tab && "_blank"} key={"navbar_menu_" + index} className="nav-link">{it.name}</Nav.Link>;
                            })
                        }
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default Connector(PublicNavbarComponent);


import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import map_image from "../../../statics/images/map.png";

class ContactUs extends Component {
    render() {
        return (
            <Container>
                <div className={"py-2"}>
                    <h2 className={"text-center"}>Contact Us</h2>
                    <Row className={"g-0"}>
                        <Col md={12} className={"p-2"}>
                            <div className={"shadow rounded overflow-hidden"}>
                                <a href={"https://goo.gl/maps/PEKGKkHHLmrtV2vo6"} rel="noreferrer" target={"_blank"}>
                                    <img alt={"map"} className={"w-100"} src={map_image} />
                                </a>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={"bg-white shadow p-2 rounded m-2 text-center"}>
                                <h4 className={"mt-3"}>
                                    Contact
                                </h4>
                                <p className={"m-0"} style={{fontSize: 20}}>
                                    +91 87652 75017
                                </p>
                                <p className={"m-0"} style={{fontSize: 20}}>
                                    +91 88405 93635
                                </p>
                            </div>
                        </Col>
                        <Col md={6} className={"p-2"}>
                            <div className={"bg-white shadow p-2 rounded h-100 text-center"}>
                                <h4 className={"mt-3"}>
                                    Email
                                </h4>
                                <p className={"m-0"} style={{fontSize: 20}}>
                                    apmakeupstudiovns@gmail.com
                                </p>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={"bg-white shadow p-2 rounded m-2 text-center"}>
                                <h4 className={"mt-3"}>
                                    Address
                                </h4>
                                <p style={{fontSize: 20}}>
                                    AP makeup studio <br />
                                    B 36/36, B-1 <br />
                                    Next to Jalan wholesale <br />
                                    Above GPS Jewellers <br />
                                    Tulsi Manas Mandir <br />
                                    Durgakund <br />
                                    Varanasi 221005
                                </p>
                                <p style={{fontSize: 20}}>
                                    <a href={"https://goo.gl/maps/PEKGKkHHLmrtV2vo6"} rel="noreferrer" target={"_blank"}>Find us on map </a>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    }
}

export default ContactUs;
import React, {Component} from 'react';
import {Container, Spinner} from "react-bootstrap";

class AboutUs extends Component {

    constructor(props) {
        super(props);
        this.OnSuccessAboutUsPage = this.OnSuccessAboutUsPage.bind(this);
        this.OnFailureGetAboutUs = this.OnFailureGetAboutUs.bind(this);
    }
    state = {
        about_us_description: [
            {
                content: "Make-up is an art form, much more sensitive than any other form of art. This not only beautifies a person but also infuses a huge level of confidence and boosts the energy levels to an extent that it can make a lot of difference in the personality. We realized this and focused on taking make-up art to a new level."
            },
            {
                content: "Anjali Pandey has done her masters in Fine Arts from world renowned Banaras Hindu University. This training imparts her a superior understanding of the science of colours, hues, tints, shades and tones along with a play of their various combination. Taking a step further Anjali Pandey has completed higher training in make-up artistry and is now offering her services to her valuable customers"
            },
            {
                content: "Anjali Pandey is also a renowned model. She has the need to wear a make-up on a regular basis. No other than she can fully understand the risks associated with cosmetic products and the frequent application of make-up. She believes in the minimalist approach with the best available cosmetic products to deliver the best results every time."
            }
        ]
    }

    componentDidMount() {
        // getAboutUsPage(this.props, this.props.match, this.OnSuccessAboutUsPage, this.OnFailureGetAboutUs);
    }

    OnSuccessAboutUsPage(data) {
        console.log(data);
        this.setState({
            about_us_description: "About us not exists"
        });
    }

    OnFailureGetAboutUs(error) {
        console.error(error);
        this.setState({
            about_us_description: "About us not exists"
        });
    }

    render() {
        return (
            <div>
                <div className={"p-2"}>
                    <h2 className={"text-center"}>About Us</h2>
                    {
                        this.state.about_us_description?
                            <Container>
                                {this.state.about_us_description.map(content => {
                                    return <p className={"text-center py-2"} style={{fontSize: 24}}>
                                        {
                                            content.content
                                        }
                                    </p>
                                })}
                            </Container>
                            :<div>
                                <Spinner animation={"border"} />
                            </div>
                    }
                </div>
            </div>
        );
    }
}

export default AboutUs;
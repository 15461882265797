import React, {Component} from 'react';
import {Col, Container, Placeholder, Row} from "react-bootstrap";
import {BackendGETRequest} from "../../webrequests/BackendRequest";
import {PUBLIC_GET_SERVICE_TAB_ITEMS_API} from "../../../constants/backend/ApiPublicConstant";
import {SERVICE_ACCOUNT_PARAM} from "../../../constants/ParamConstant";
import {SERVICE_ACCOUNT_ID} from "../../../constants/Constant";
import image_121 from "../../../statics/images/services/121.jpg";
import image_122 from "../../../statics/images/services/122.jpg";
import image_123 from "../../../statics/images/services/123.jpg";

class ServiceTab extends Component {
    state = {
        service_tab_items: [],
        is_loading: true,
        something_went_wrong: false,
    }

    constructor(props) {
        super(props);
        this.OnReceivedServiceTabItems = this.OnReceivedServiceTabItems.bind(this);
        this.OnFailure = this.OnFailure.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, PUBLIC_GET_SERVICE_TAB_ITEMS_API.replace(SERVICE_ACCOUNT_PARAM, SERVICE_ACCOUNT_ID), this.OnReceivedServiceTabItems, this.OnFailure)
    }

    OnReceivedServiceTabItems(response) {
        this.setState({
            is_loading: false,
            service_tab_items: response.body["serviceTabItemResponses"]
        })
    }

    OnFailure() {
        this.setState({
            is_loading: false,
            something_went_wrong: true
        })
    }

    render() {
        return (
            <div>
            <Container>
                <div className={"p-2"}>
                    <h4>Services</h4>
                    <p className={"text-muted m-0"}>
                        Services that we are offering
                    </p>
                </div>
                <div className={"py-2"}>
                    <Row className={"g-0"}>
                        {
                            this.state.service_tab_items.map((service_tab_item, index) => {
                                return <Col className={"p-2"} sm={6} md={4} lg={4} key={"service" + index}>
                                    <div className={"hover-shadow transition-medium shadow-sm"}>
                                        <img src={service_tab_item.image} alt={"services"} className={"w-100 rounded-3"}/>
                                    </div>
                                </Col>
                            })
                        }
                        {
                            this.state.is_loading && [...Array(6)].map(()=> <Placeholder as={Col} animation={"glow"} className={"p-2"} sm={6} md={4} lg={4} >
                                <Placeholder  sm={12} className={"rounded"} style={{height: 200}} />
                            </Placeholder>)
                        }
                    </Row>
                    {
                        this.state.something_went_wrong &&
                        <div className={"p-2 text-secondary d-flex justify-content-center align-items-center"} style={{height: 200}}>
                            Something went wrong to load services
                        </div>
                    }
                </div>
                <div className={"py-2"}>
                    <img src={image_121} alt={"service_1"} className={"w-100"} />
                    <img src={image_122} alt={"service_1"} className={"w-100"} />
                    <img src={image_123} alt={"service_1"} className={"w-100"} />
                </div>
            </Container>
            </div>
        );
    }
}

export default ServiceTab;
import React, {Component} from 'react';
import {Carousel} from "react-bootstrap";

class CarouselPanel extends Component {
    render() {
        return (
            <Carousel controls={false} >
                {
                    this.props.items &&
                    this.props.items.map( (item, index) => {
                        return <Carousel.Item key={"carousel_item_" + index}>
                            <div style={{height: "30vw"}} className={""}>
                                <img src={item.image} alt="carousel-item" className={"img-object-fit-cover"} />
                            </div>
                            <Carousel.Caption>
                                <h3>{item.label}</h3>
                                <p>{item.content}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    })
                }
            </Carousel>
        );
    }
}

export default CarouselPanel;
import React, {Component} from 'react';
import ServiceBottomPanel from "./footer/ServiceBottomPanel";
import PublicNavbarComponent from "./navbar/PublicNavbarComponent";
import {Route, Switch} from "react-router-dom";
import ServiceHome from "./home/ServiceHome";
import AboutUs from "./about_us/AboutUs";
import {
    ABOUT_US_URL, CONTACT_US_URL,
    HOME_URL,
    MISSION_URL,
    OFFERS_URL, POLICY_URL,
    SERVICES_URL, TESTIMONIALS_URL
} from "../../constants/application/ApplicationUrl";
import OffersPage from "./offers/OffersPage";
import Testimonials from "./testimonials/Testimonials";
import ServiceTab from "./services/ServiceTab";
import ContactUs from "./contact_us/ContactUs";
import Policies from "./policies/Policies";
import Mission from "./mission/Mission";


class ServiceApp extends Component {
    render() {
        return (
            <div className={"flex-grow-1 d-flex flex-column"}>
                <PublicNavbarComponent />

                <div className={"flex-grow-1"}>
                    <Switch>
                        <Route path={HOME_URL} exact={true} component={ServiceHome}/>
                        <Route path={ABOUT_US_URL} exact={true} component={AboutUs} />
                        <Route path={MISSION_URL} exact={true} component={Mission} />
                        <Route path={SERVICES_URL} exact={true} component={ServiceTab} />
                        <Route path={OFFERS_URL} exact={true} component={OffersPage} />
                        <Route path={TESTIMONIALS_URL} exact={true} component={Testimonials} />
                        <Route path={CONTACT_US_URL} exact={true} component={ContactUs} />

                        <Route path={POLICY_URL} exact={true} component={Policies} />
                    </Switch>
                </div>
                <ServiceBottomPanel account={this.props.match.params.SERVICE_ACCOUNT} />
            </div>
        );
    }
}

export default ServiceApp;
import React, {Component} from 'react';
import CarouselPanel from "../../general/CarouselPanel";
import b1 from "../../../statics/images/ap/b1.jpg";
import b2 from "../../../statics/images/ap/b2.png";
import c1 from "../../../statics/images/ap/c1.jpeg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Container} from "react-bootstrap";

class ServiceHome extends Component {

    state = {
        bottom_icon: {
            whatsapp: {
                enabled: true,
                link: "https://wa.me/918840593635"
            },
            facebook: {
                enabled: true,
                link: "https://www.facebook.com/MakeupbyAnjaliPandey"
            },
            instagram: {
                enabled: true,
                link: "https://www.instagram.com/makeupbyanjalipandey"
            },
            youtube: {
                enabled: true,
                link: "https://www.youtube.com/channel/UCnUusV2obpk0g1S8v7Bn1_A"
            },
            jd: {
                enabled: true,
                link: "https://www.justdial.com/Varanasi/Ap-Makeup-Studio-Durgakund/0542PX542-X542-220522085006-F9G3_BZDET"
            }
        },
        why_our_service: [
            {
                title: "Luxurious Studio",
                content: "Aesthetically luxurious decor with a soothing ambience"
            },
            {
                title: "Teamwork",
                content: "We boast a very friendly team and reassure that you will never feel left out."
            },
            {
                title: "High Quality Products",
                content: "We use high quality products to get the best outcome"
            },
            {
                title: "Free WIFI",
                content: "Connect to our WIFI and enjoy the high speed internet to share your experience"
            },
            {
                title: "Music",
                content: "Connect to our Amplifier with Bluetooth to the surround sound music system and immerse yourself"
            },
            {
                title: "Coffee & Snacks",
                content: "Enjoy Coffee and snacks whilst you get the makeup done or waiting."
            },
            {
                title: "Google 5 star business",
                content: "100% satisfaction guaranteed."
            },
            {
                title: "Central Location",
                content: "central location in Varanasi with easy access."
            },
            {
                title: "Long opening hours",
                content: "easily available anytime"
            },
        ]
    }

    items = [
        {
            image: b1,
            label: "",
            content: ""
        },
        {
            image: b2,
            label: "",
            content: ""
        }
    ]

    render() {
        return (
            <section className={"position-relative"}>
                <div className={"flex-fill"}>
                    <CarouselPanel items={this.items} />
                </div>
                <div className={""}>
                    <img src={c1} alt={"banner"} className={"w-100"}/>
                </div>

                <div className={"py-5"}>
                    <Container>
                        <h1 className={"fw-bold"}>
                            Why our service
                        </h1>
                        <p className={"text-muted px-1"}>
                            AP Makeup Studio is managed by Anjali Pandey who is a fully qualified and a best professional makeup Artist with a masters in fine arts from Banaras Hindu University. She is an expert in Bridal Makeup for all other occasions.
                        </p>
                        <div className={"d-flex flex-wrap"}>
                            {
                                this.state.why_our_service.map(wos => {
                                    return <div className={"col-md-4 p-1"}>
                                        <div className={"shadow-sm p-2 bg-white rounded h-100"}>
                                            <h5>
                                                {wos.title}
                                            </h5>
                                            <p>
                                                {wos.content}
                                            </p>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <p className={"text-muted px-1"}>
                            Anjali Pandey has an illustrious career as a model
                            Has a clear understanding of the exact extent of makeup required
                            Knows well how to look after the skin avoiding negative effects of makeup
                            Has a minimalistic approach
                        </p>
                    </Container>
                </div>

                <div className={"py-5 bg-white"}>
                    <Container>
                        <h1 className={"fw-bold"}>
                            Journey of AP makeup Studio
                        </h1>
                        <div>
                            Founder and CEO Anjali Pandey started her journey by joining Fine Arts in Banaras Hindu University (BHU). Art has always intrigued her and imparted the ability to express herself more effectively. After completing her graduation she pursued further for her Master’s degree. On the way she also received special recognition with a scholarship and received various awards. She also started an NGO to promote the development of young minds by the means of art.
                            She soon realized that Makeup Artistry is her niche which stimulated her mind most and realized that Make-up is an art form, much more sensitive than any other form of art. This not only beautifies a person but also infuses a huge level of confidence and boosts the energy levels to an extent that it can make a lot of difference in the personality and also express a personality in the most opulent ways. This enabled her on taking make-up art to a new level.
                            Anjali Pandey has a strong belief that right makeup is the answer rather than just more makeup. Her masters in Fine Arts from world renowned Banaras Hindu University imparts her a superior understanding of the science of colours, hues, tints, shades and tones along with a play of their various combination. Taking a step further Anjali Pandey has completed higher training in make-up artistry and is now offering her services to her valuable customers.
                            Anjali Pandey is also a renowned model. She has the need to wear a make-up on a regular basis. No other than she can fully understand the risks associated with cosmetic products and the frequent application of make-up. She believes in the minimalist approach with the best available cosmetic products to deliver the best results every time.
                            The current Beauty Salon market is managed by sub optimally trained staff and the managers do not have full understanding of makeup. Noticing such a huge gap in the industry Anjali Pandey decided to setup a luxurious Makeup Studio of the highest standards providing facilities like Best Bridal makeup in Varanasi. Since inception of AP Makeup Studio – Best Beauty Parlour in Varanasi we have had an exceptional response with 100% satisfied clients. Anjali Pandey as a senior Makeup Artist has developed a passionate team of makeup professionals and has gained enough grounds. She has been rated as a 5 Star Google Business on the internet. It has come across as a strongly recommended Beauty Parlor in Varanasi
                        </div>
                    </Container>
                </div>

                <div className={"position-fixed bottom-0 end-0 p-2 pb-5"}>
                    <div className={"d-flex flex-column"}>
                        {
                            this.state.bottom_icon.whatsapp.enabled &&
                            <a href={this.state.bottom_icon.whatsapp.link} rel={"noreferrer"} target={"_blank"}
                               className={"my-1 p-2 no-text-decor rounded-circle d-flex justify-content-center align-items-center"}
                               style={{width: 50, height: 50, backgroundColor: "#25D366"}}>
                                <FontAwesomeIcon icon={["fab", "whatsapp"]} size={"2x"} color={"white"}/>
                            </a>
                        }

                        {
                            this.state.bottom_icon.facebook.enabled &&
                            <a href={this.state.bottom_icon.facebook.link} rel={"noreferrer"} target={"_blank"}
                               className={"my-1 p-2 no-text-decor rounded-circle d-flex justify-content-center align-items-center"}
                               style={{width: 50, height: 50, backgroundColor: "#3b5998"}}>
                                <FontAwesomeIcon icon={["fab", "facebook"]} size={"2x"} color={"white"}/>
                            </a>
                        }

                        {
                            this.state.bottom_icon.instagram.enabled &&
                            <a href={this.state.bottom_icon.instagram.link} rel={"noreferrer"} target={"_blank"}
                               className={"my-1 p-2 no-text-decor rounded-circle d-flex justify-content-center align-items-center"}
                               style={{
                                   width: 50,
                                   height: 50,
                                   backgroundColor: "#fed373",
                                   background: "radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)"
                               }}>
                                <FontAwesomeIcon icon={["fab", "instagram"]} size={"2x"} color={"white"}/>
                            </a>
                        }

                        {
                            this.state.bottom_icon.youtube.enabled &&
                            <a href={this.state.bottom_icon.youtube.link} rel={"noreferrer"} target={"_blank"}
                               className={"my-1 p-2 no-text-decor rounded-circle d-flex justify-content-center align-items-center"}
                               style={{width: 50, height: 50, backgroundColor: "#FF0000"}}>
                                <FontAwesomeIcon icon={["fab", "youtube"]} size={"2x"} color={"white"}/>
                            </a>
                        }

                        {
                            this.state.bottom_icon.jd.enabled &&
                            <a href={this.state.bottom_icon.jd.link} rel={"noreferrer"} target={"_blank"}
                               className={"my-1 p-2 no-text-decor rounded-circle d-flex justify-content-center align-items-center bg-white"}
                               style={{width: 50, height: 50}}>
                                <span className={"fw-bold"} style={{color: "#116DB6", fontSize: 28}}>J</span>
                                <span className={""} style={{color: "#F26600", fontSize: 28}}>d</span>
                            </a>
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export default ServiceHome;
import { UserActionTypes } from "./user.types";

export const setUserName = username => (
    {
        type: UserActionTypes.SET_USERNAME,
        payload:username
    }
)

export const setToken = token => (
    {
        type: UserActionTypes.SET_USER_TOKEN,
        payload:token
    }
)

export const resetUserData = () => ({
    type: UserActionTypes.RESET_USER_DATA
})

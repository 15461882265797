import {GetAppBackendUrl} from "../../utils/OnlineSellUtil";


export const GET_USER_API = GetAppBackendUrl() + "/api/user";
export const LOGOUT_API = GetAppBackendUrl() + "/api/logout/";
export const GET_TOKEN_API = GetAppBackendUrl() + "/auth/authenticate/token/google_oauth2";

export const GET_USER_SERVICE_ACCOUNTS_API = GetAppBackendUrl() + "/api/user/service";
export const GET_USER_SERVICE_ACCOUNT_TYPE_API = GET_USER_SERVICE_ACCOUNTS_API + "/business_types";
export const GET_USER_SERVICE_ACCOUNT_API = GET_USER_SERVICE_ACCOUNTS_API + "/get/";
export const CREATE_USER_SERVICE_ACCOUNT_API = GET_USER_SERVICE_ACCOUNTS_API + "/create";
export const UPDATE_USER_SERVICE_ACCOUNT_API = GET_USER_SERVICE_ACCOUNTS_API + "/update";
export const DELETE_USER_SERVICE_ACCOUNT_API = GET_USER_SERVICE_ACCOUNTS_API + "/delete";

export const GET_SERVICE_ACCOUNT_ABOUT_US_API = GET_USER_SERVICE_ACCOUNTS_API + "/about_us";
export const UPDATE_USER_SERVICE_ABOUT_US_API = GET_USER_SERVICE_ACCOUNTS_API + "/update";

export const GET_ADDRESS_API = GetAppBackendUrl() + "/api/user/address"
export const CREATE_ADDRESS_API = GET_ADDRESS_API + "/create";
export const UPDATE_ADDRESS_API = GET_ADDRESS_API + "/update";
export const DELETE_ADDRESS_API = GET_ADDRESS_API + "/delete";

import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Testimonials extends Component {

    constructor(props) {
        super(props);
        this.Review = this.Review.bind(this);
    }

    state = {
        testimonials: [
            {
                name: "Neha",
                review: "I have finally got around after my wedding and things seems to be settling now. It would be injustice to the AP Makeup Studio team if I did not leave a review.My wedding was in the month of March and I was extremely worried as it was in the village setting.I appointed Miss Anjali Pandey to be my MUA as I had followed her for long and aware of her good credentials and the background of Arts.She gave me a perfect look and I was overwhelmed with the quality of the makeup. I was me x 10 and everyone appreciated my looks.My heartiest thanks to AP Makeup Studio for making my day."
            },
            {
                name: "saba",
                review: "I am very thin and have an oblong face with very prominent eyes. Have always got ready at home and did minimal makeup myself for most occasions. Came across Makeup by Anjali Pandey, AP Makeup Studio through my friend. I am just amazed from the transformation I got with minimal intervention. This has changed the outlook of myself and I strongly advise that everybody should get makeup done by her as she has a thorough understanding due to her background of Arts and being a model herself. She is extremely friendly and gave me a good discount too."
            },
            {
                name: "Vaishnavi",
                review: "Best place I have went in Varanasi people out of varanasi should also try because people of Varanasi prefer this place to go for there make up coz it`s only best place in Varanasi love it thank you"
            },
            {
                name: "Amit",
                review: "Outstanding! Very responsive and easy to communicate with. Anjali Pandey was not only punctual and competent, but she is also a highly skilled makeup artist. Her work was excellent in every way! Without hesitation, I would suggest!"
            },
            {
                name: "ayushi pandey",
                review: "Just go for it and be the next beautiful diva"
            },
            {
                name: "rishita sarda",
                review: "Excellent"
            }
        ]
    }

    Review({name, review}) {
        return <div className={"d-flex bg-white shadow-sm rounded p-2 my-2"}>
            <div className={"p-2"}>
                <div className={"p-2 rounded-circle border"}>
                    <FontAwesomeIcon icon={"user"} style={{height: 36, width: 36}} />
                </div>
            </div>
            <div className={"p-2"}>
                <h6>
                    {name}
                </h6>
                <p>
                    {review}
                </p>
            </div>
        </div>;
    }

    render() {
        return (
            <Container>
                <div className={"py-2"}>
                    <h6>Review from the users</h6>
                    {
                        this.state.testimonials.map(testimonial => {
                            return <this.Review name={testimonial.name} review={testimonial.review} />;
                        })
                    }
                </div>
            </Container>
        );
    }
}

export default Testimonials;
import { ModalActionTypes } from "./modal.types";

export const showLoadingScreen = isShow => (
    {
        type: ModalActionTypes.SET_LOADING_SCREEN,
        payload:isShow
    }
)

export const setErrorModalScreen = model_data => (
    {
        type: ModalActionTypes.SET_ERROR_MODAL_SCREEN,
        payload: model_data
    }
)

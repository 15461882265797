import React, {Component} from 'react';
import {Container, Spinner} from "react-bootstrap";
import first_image from "../../../statics/images/mission/first.png";
import second_image from "../../../statics/images/mission/second.png";
import third_image from "../../../statics/images/mission/third.png";
import fourth_image from "../../../statics/images/mission/fourth.png";
import fifth_image from "../../../statics/images/mission/fifth.png";
import sixth_image from "../../../statics/images/mission/sixth.png";
import seventh_image from "../../../statics/images/mission/seventh.png";
import eighth_image from "../../../statics/images/mission/eighth.png";
import ninth_image from "../../../statics/images/mission/ninth.png";
import tenth_image from "../../../statics/images/mission/tenth.png";
import eleventh_image from "../../../statics/images/mission/eleventh.png";
import final_image from "../../../statics/images/mission/final.png";




class Mission extends Component {

    constructor(props) {
        super(props);
        this.OnSuccessAboutUsPage = this.OnSuccessAboutUsPage.bind(this);
        this.OnFailureGetAboutUs = this.OnFailureGetAboutUs.bind(this);
    }
    state = {
        about_us_description: [
            {
                image: second_image,
                content: "We love to exceed your expectations"
            },
            {
                image: third_image,
                content: "Beautify your face"
            },
            {
                image: fourth_image,
                content: "Leaving you feeling great "
            },
            {
                image: fifth_image,
                content: "Offering you a Sweet Escape  "
            },
            {
                image: sixth_image,
                content: "……. Even sweeter prices.    "
            },
            {
                image: seventh_image,
                content: "Hence, we encourage you to step out to Indulge  "
            },
            {
                image: eighth_image,
                content: "And submerge yourself in the world of beauty "
            },
            {
                image: ninth_image,
                content: "As you deserve nothing but the best     "
            },
            {
                image: tenth_image,
                content: "As an individual as you are. "
            },
            {
                image: eleventh_image,
                content: "We welcome to our world where passion meets creativity.   "
            }
        ]
    }

    componentDidMount() {
        // getAboutUsPage(this.props, this.props.match, this.OnSuccessAboutUsPage, this.OnFailureGetAboutUs);
    }

    OnSuccessAboutUsPage(data) {
        console.log(data);
        this.setState({
            about_us_description: "About us not exists"
        });
    }

    OnFailureGetAboutUs(error) {
        console.error(error);
        this.setState({
            about_us_description: "About us not exists"
        });
    }

    render() {
        return (
            <div>
                <div className={"p-2"}>
                    {/*<h2 className={"text-center"}>Mission</h2>*/}
                    {
                        this.state.about_us_description?
                            <Container>
                                <div className={"text-center"}>
                                    <img alt={"im1"} src={first_image} style={{width: 400}} />
                                </div>
                                {this.state.about_us_description.map((content, index) => {
                                    return <div key={"im_mission_" + index} className={"d-flex p-2 m-2 justify-content-center align-items-center"}>
                                        <p className={"p-2"} style={{fontSize: 24}}>
                                            {
                                                content.content
                                            }
                                        </p>
                                        <img src={content.image} alt={"im_mission_" + index} style={{height: 100}}/>
                                    </div>
                                })}

                                <div className={"text-center"}>
                                    <img alt={"im1"} src={final_image} style={{width: 300}} />
                                </div>
                            </Container>
                            :<div>
                                <Spinner animation={"border"} />
                            </div>
                    }
                </div>
            </div>
        );
    }
}

export default Mission;
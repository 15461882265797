import axios from "axios";
import {optionProvider} from "./RequestConfig";
import {GET_TOKEN_API, GET_USER_API} from "../../constants/backend/ApiConstant";
import {SUCCESS_STATUS} from "../../constants/Constant";

function GetUser(props) {

    axios.get(GET_USER_API, optionProvider(props))
        .then(response => {
            if(response.data.status === SUCCESS_STATUS) {
                props.setUserName(response.data.body["username"]);
            }
        })
        .catch(error => {
            console.log(error);
        });
}

export default function Authenticate(props, tokenId) {
    axios.post(GET_TOKEN_API, {"token": tokenId}, optionProvider(props))
        .then(res => {
            props.setToken(res.data["message"]);
            GetUser(props);
        })
        .catch(error => {
            console.log(error);
            alert(error);
        });
}
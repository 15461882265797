import React, {Component} from 'react';
import a1 from "../../../statics/images/ap/a1.jpeg";
import a2 from "../../../statics/images/ap/a2.jpeg";
import a3 from "../../../statics/images/ap/a3.jpeg";
import a4 from "../../../statics/images/ap/a4.jpeg";
import a5 from "../../../statics/images/ap/a5.jpeg";
import a6 from "../../../statics/images/ap/a6.jpeg";
import {Col, Container, Row} from "react-bootstrap";
import c2 from "../../../statics/images/ap/c2.jpeg";

class OffersPage extends Component {

    state = {
        images: [
            {
                image: a1
            },
            {
                image: a2
            },
            {
                image: a3
            },
            {
                image: a4
            },
            {
                image: a5
            },
            {
                image: a6
            }
        ]
    }

    render() {
        return (
            <section>
                <div className={"p-2"}>
                    <Container>
                        <div className={"p-3 border bg-white"}>
                            <img src={c2} alt={"banner"} className={"w-100 img-object-fit-cover"}/>
                        </div>

                        <div className={"py-4"}>
                            <Row className={"g-0"}>
                                {
                                    this.state.images.map((image, index) => {
                                        return <Col className={"p-3"} sm={6} md={4} lg={4} key={"service" + index}>
                                            <div className={"hover-shadow transition-medium shadow-sm"}>
                                                <img src={image.image} alt={"services"} className={"w-100 rounded-3"}/>
                                            </div>
                                        </Col>;
                                    })
                                }
                            </Row>
                        </div>

                        <div className={"p-3 rounded overflow-hidden"}>
                            <iframe width="100%" height="712" src="https://www.youtube.com/embed/V7Mg8E2SGUo"
                                    title="Welcome to AP Makeup Studio"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; mute"
                                    allowFullScreen></iframe>
                        </div>
                    </Container>
                </div>
            </section>
        );
    }
}

export default OffersPage;